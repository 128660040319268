@tailwind base;
@tailwind components;
@tailwind utilities;

.myCustomIcon:hover {
    color: #3fb270;
}

.myCustomIconEdit:hover {
    color: blue;
}


.myCustomIconDelete:hover {
    color: red;
}
.orangeicon:hover {
    color: #e67d34;
}